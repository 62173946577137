<template>
  <div class="bg-shop-summary">
    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          营收数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            style="width: 220px;"
            v-model="daterangeDate"
            type="daterange"
            size="medium"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading" @click="downZd()">
            导出当前数据
          </el-button>
        </div>
      </div>

      <div id="priceList" :style="{height: '50vh'}"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          每日营收统计
        </div>
        <div class="col-auto">
          <el-date-picker
            value-format="timestamp"
            @change="monthChange"
            v-model="month"
            size="medium"
            type="month"
            placeholder="请选择月份"
          ></el-date-picker>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading1" @click="downZd1()">导出月度营收统计</el-button>
        </div>
      </div>

      <en-table-layout :toolbar="false" pagination :tableData="pageData.data" :loading="loading">
        <template slot="table-columns">
          <el-table-column label="日期">
            <template slot-scope="scope">{{ scope.row.time }}</template>
          </el-table-column>
          <el-table-column label="利润">
            <template slot-scope="scope">￥{{ scope.row.profitTurnoverPrice }}</template>
          </el-table-column>
          <el-table-column label="营收总额">
            <template slot-scope="scope">￥{{ scope.row.turnoverPrice }}</template>
          </el-table-column>
          <el-table-column label="下单数">
            <template slot-scope="scope">{{ scope.row.orderTotalNum }}</template>
          </el-table-column>
          <el-table-column label="支付订单数">
            <template slot-scope="scope">{{ scope.row.orderPayNum }}</template>
          </el-table-column>
        </template>
      </en-table-layout>
    </el-card>
  </div>
</template>

<script>
import * as API_buyAnyalysis from "@/api/buyAnyalysis";
import { CategoryPicker } from "@/components";
import { Foundation } from "@/../ui-utils";
import { handleDownload } from "@/utils";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
export default {
  name: "buyAnalysis",
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "近7日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "近15日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "近30日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      importLoading1: false,

      daterangeDate: "",

      /** 图表参数*/
      params: {},
      params1: {
        cycle_type: "month"
      },
      month: new Date(),
      pageData: {data: []},
      data: {},

      tableHeight: document.body.clientHeight / 2
    };
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

    this.params.start_time = parseInt(start / 1000);
    this.params.end_time = parseInt(end / 1000);

    // this.params.month

    this.GET_PriceStatistics();
    this.GET_PriceStatistics1();
  },
  mounted() {
    window.onresize = this.countTableHeight;
    this.$nextTick(() => {
      this.priceListChart = this.$echarts.init(
        document.getElementById("priceList")
      );
    });
  },
  methods: {
    resetTimeParams() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

      this.params.start_time = parseInt(start / 1000);
      this.params.end_time = parseInt(end / 1000);
    },
    downZd() {
      let data = { ...this.data };
      this.importLoading = true;
      let response = [];
      data.xAxis.forEach(item => {
        response.push({ date: item });
      });
      data.series[0].data.forEach((item, index) => {
        response[index]["ze"] = item;
      });
      data.series[1].data.forEach((item, index) => {
        response[index]["lr"] = item;
      });
      let tHeaders = ["日期", "营收总额", "利润"];
      let filterVals = ["date", "ze", "lr"];
      handleDownload(response, tHeaders, filterVals, "营收数据");
      this.importLoading = false;
    },
    downZd1() {
      this.importLoading1 = true;
      let tHeaders = ["日期", "利润", "营收总额", "下单数", "支付订单数"];
      let filterVals = [
        "time",
        "profitTurnoverPrice",
        "turnoverPrice",
        "orderTotalNum",
        "orderPayNum"
      ];
      const name = `${this.month.getFullYear()}年${this.month.getMonth() + 1}月商品统计`;
      handleDownload(this.pageData.data, tHeaders, filterVals, name);
      this.importLoading1 = false;
    },
    monthChange(val) {
      this.params1.year =
        Foundation.unixToDate(parseInt(val / 1000), "yyyy") || "";
      this.params1.month =
        Foundation.unixToDate(parseInt(val / 1000), "M") || "";
      this.GET_PriceStatistics1();
    },
    daterangeDateChange(val) {
      if (val) {
        this.params.start_time = val ? parseInt(val[0] / 1000) : "";
        this.params.end_time = val ? parseInt(val[1] / 1000) : "";
      } else {
        this.resetTimeParams();
      }
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight / 2;
      /** 图表刷新 */
      setTimeout(this.priceListChart.resize);
    },
    /** 执行搜索 */
    handleSearch() {
      this.GET_PriceStatistics();
    },

    GET_PriceStatistics() {
      API_buyAnyalysis.enterpriseMoney(this.params).then(response => {
        this.data = response;
        this.priceListChart.setOption({
          tooltip: { trigger: "axis" },
          grid: {
            x: "50px",
            y: "80px",
            x2: "60px",
            y2: "30px"
          },
          legend: {
            show: true,
            data: [{ name: "营收总额" }, { name: "利润" }],
            top: "10px"
          },
          color: ["#7CB5EC"],
          toolbox: {
            feature: {
              magicType: { type: ["line", "bar"] },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: "日期",
            type: "category",
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: "金额",
            type: "value",
            axisLabel: {
              formatter: "{value}"
            }
          },
          series: [
            {
              type: "line",
              data: response.series[0].data,
              name: "营收总额",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: "#1a43a9"
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#c2bbfe"
                    },
                    {
                      offset: 1,
                      color: "#d3edfb"
                    }
                  ])
                }
              },
              barWidth: 50,
              areaStyle: {
                normal: {}
			  },
			  symbol: "none",
              smooth: true
            },
            {
              type: "line",
              name: "利润",
              data: response.series[1].data,
			  smooth: true,
			  symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: "#4fa736"
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#acd9a9"
                    },
                    {
                      offset: 1,
                      color: "#d0eccd"
                    }
                  ])
                }
              },
              areaStyle: {
                normal: {}
              },
              barWidth: 50
            }
          ]
        });
      });
    },
    GET_PriceStatistics1() {
      this.loading = true;
      API_buyAnyalysis.getTurnoverMonthData(this.params1).then(response => {
        this.pageData.data = response;
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

